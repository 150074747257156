var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('div',[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.group ? _vm.entry.name : `${_vm.$tc("new")} ${_vm.$t("user_group").toLowerCase()}`)+" "),(!_vm.group)?_c('Tooltip',{attrs:{"title":_vm.$t('hints.new_group')}}):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.name),expression:"entry.name"}],ref:"group_name",staticClass:"form-control",attrs:{"type":"text","data-testid":"name","data-toggle":"popover","data-content":_vm.group
              ? _vm.group.is_system
                ? _vm.$t('titles.group_is_system')
                : _vm.$t('titles.group_name')
              : '',"placeholder":_vm.$t('name'),"readonly":(_vm.group && _vm.group.is_system) ||
              !_vm.$can('manage', 'GrupoDeUsuariosEscrita'),"required":""},domProps:{"value":(_vm.entry.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.entry, "name", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.description),expression:"entry.description"}],staticClass:"form-control",attrs:{"type":"text","data-testid":"description","data-toggle":"popover","data-content":_vm.group
              ? _vm.group.is_system
                ? _vm.$t('titles.group_is_system')
                : _vm.$t('titles.group_description')
              : '',"placeholder":_vm.$t('description'),"readonly":(_vm.group && _vm.group.is_system) ||
              !_vm.$can('manage', 'GrupoDeUsuariosEscrita'),"required":""},domProps:{"value":(_vm.entry.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.entry, "description", $event.target.value)}}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }